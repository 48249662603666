.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Custom CSS */

.bg {
  background-image: url(./Images/oercbg.png);
  background-repeat: repeat;
  background-position: center;
  background-size: cover;
}

.notic {
  align-items: center;
  vertical-align: middle;
  margin: 0px 12px;
}

.min-h-screen {
  min-height: 100vh;
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.nugstart {
  padding: 10px 20px;
  background-color: #F0F0F0;
  align-items: center;
  margin-right: 0 !important;
}

.nug {
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
}

.subbannertitle {
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
}

.nugctext {
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
}

.topbar2 {
  align-items: center;
  padding-top: 10px;
}

.cardlogo {
  width: 100%;
  margin-top: 20px;
}

.hypernug {
  color: black;
  font-style: normal;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
}

.agreementicn {
  width: 42px !important;
  padding-top: 40px;
}

.ptitle {
  font-weight: bold;
  margin: 16px 0;
  font-size: 20px;
  text-align: center;
}

.pcontact {
  font-size: 13px;
}

.tnc {
  text-align: center !important;
}

.link {
  text-decoration: none;
  color: #0F55B2;
  cursor: pointer;
}

.agreebtn {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.optionaltxt {
  color: gray;
  font-weight: 300;
}

.mandatorytxt {
  color: red;
}

.oerctitle {
  margin: 20px 0px;
}

.progress {
  margin: 20px 0px;
}

.photonotic {
  align-items: center;
  text-align: center;
  font-size: small;
}

.center {
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
}

.introtxt {
  padding-top: 69px;
  padding-bottom: 69px;
  /* padding: 69px, 20px; */
  padding-right: 20px;
  padding-left: 20px;
}