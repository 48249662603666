.banner {
    background-color: #2F5AAE;
    text-align: center;
    align-content: center;
    padding: 5rem 1rem;
    color: white; 
}

.tnctou {
    padding: 40px;
}

.tnctxt {
    margin-top: 40px;
    text-align: left !important;
}

.tnctxttitle {
    font-weight: bold;
}

.tnctxtcontent {
    margin-top: 20px;
}

.centertnc {
    background-color: #2F5AAE;
    text-align: center;
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}