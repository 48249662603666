.title {
    font-weight: bold;
    color: #333;
    padding-bottom: 0.5rem;
}

.subtitle {
    font-weight: bold;
    color: #63646e;
    padding-bottom: 0.5rem;
}

.secRow div {
    display: inline-block;
    width: auto !important;
    padding-right: 0 !important;
}

.secRow {
    flex-direction: row;
}

.checkBox {
    width: 36%;
    display: inline-block;
}

.copycontainer {
    padding: 26px;
    border-width: 2;
    border-radius: 20px;
    border-color: #eeeeee;
    border-style: dashed;
    outline: none;
}